<template>
  <v-card tile class="mx-auto" :loading="tbl_ldg" :disabled="tbl_ldg">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: 'contratantes',
            }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="mr-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                color="error"
                :href="
                  url_documents +
                  '/migrations/formato_siniestralidad_2021_22.xlsx'
                "
                target="_blank"
              >
                <v-icon v-text="'mdi-download'" />
              </v-btn>
            </template>
            <span v-text="'Descargar formato'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-file-input
            v-model="data.document_file_0"
            counter
            truncate-length="15"
            label="Formato XLSX"
            accept=".xlsx"
            show-size
            dense
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col cols="12" v-if="tbl.length == 0">
          <v-btn
            block
            color="success"
            :disabled="data.document_file_0 == null"
            @click="getTbldata"
          >
            Analizar
            <v-icon right> mdi-upload </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" v-else>
          <v-btn block @click="tbl = []">
            Cargar otro formato
            <v-icon rigth> mdi-reload </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="tbl.length > 0">
          <v-card flat :loading="tbl_ldg" :disabled="tbl_ldg">
            <v-card-title>
              <span v-text="'RESULTADO'" />
              <v-spacer />
              <v-text-field
                v-model="tbl_srch"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              />
            </v-card-title>
            <v-data-table
              :headers="tbl_hdrs"
              :items="tbl"
              :items-per-page="30"
              :search="tbl_srch"
              :loading="tbl_ldg"
              :item-class="itemRowBackground"
              item-key="index"
              :expanded.sync="expanded"
              show-expand
              dense
            >
              <template v-slot:item.index="{ item }">
                <span class="font-weight-bold" v-text="item.index" />
              </template>
            </v-data-table>
            <div class="text-right pt-5 pb-3">
              <v-btn color="warning" @click.prevent="execMigration">
                Guardar
                <v-icon right> mdi-check </v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  URL_DOCUMENTS,
  rules,
  toFormData,
  dateTimeToFile,
  base64ToArrayBuffer,
} from "../../control";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      url_documents: URL_DOCUMENTS,
      tbl: [],
      tbl_ldg: false,
      tbl_srch: "",
      tbl_hdrs: [
        {
          text: "#",
          value: "index",
        },
        {
          text: "ID SM",
          filterable: true,
          value: "enrollment",
        },
        {
          text: "Nombre",
          filterable: true,
          value: "name",
        },
        {
          text: "A. paterno",
          filterable: true,
          value: "first_surname",
        },
        {
          text: "A. materno",
          filterable: true,
          value: "second_surname",
        },
        {
          text: "F. nacimiento",
          filterable: true,
          value: "birthday",
        },
      ],
      data: {},
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    fileDownload(base64, ext, name) {
      const blob = new Blob([base64ToArrayBuffer(base64)], {
        type: "application/" + ext,
      });
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("target", "_blank");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "SM_" + name + "_" + dateTimeToFile() + "." + ext;
      downloadLink.click();
    },
    getTbldata() {
      this.tbl_ldg = true;

      Axios.post(
        URL_API + "/insureds/migration/details",
        toFormData(this.data),
        headersToken(this.login.token)
      )
        .then((res) => {
          this.tbl = res.data.data.list_insureds;
          this.tbl_ldg = false;
        })
        .catch((e) => {
          console.log(e);
          this.$swal.fire(msgAlert("error", "Formato del documento invalido"));
          this.tbl_ldg = false;
        });
    },
    itemRowBackground: function (item) {
      return item.to_save
        ? item.insured
          ? ""
          : "red lighten-5"
        : "orange lighten-5";
    },
    execMigration() {
      this.$swal
        .fire(msgConfirm("¿Confirma ejecutar la migración?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.tbl_ldg = true;

            Axios.post(
              URL_API + "/insureds/migration",
              {
                list_insureds: this.tbl,
                contractor_id: this.contractor_id,
                insurance_id: this.data.insurance_id,
                insurance_migration_id: this.data.insurance_migration_id,
              },
              headersToken(this.login.token)
            )
              .then((response) => {
                response = response.data;

                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );
                if (response.success) {
                  this.$router.push({
                    name: "asegurados",
                    params: { contractor_id: this.contractor_id },
                  });
                } else {
                  console.log(response.message);
                }
              })
              .catch((error) => {
                console.log(error);
                this.$swal.fire(
                  msgAlert(
                    "error",
                    "Error en archivo de migración. Revise cuidadosamente el documento."
                  )
                );
              });
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((res) => {
      this.faqs = res.data.data;
      this.faqs_ldg = false;
    });

    this.data = {
      document_file_0: null,
    };
  },
};
</script>