var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"tile":"","loading":_vm.tbl_ldg,"disabled":_vm.tbl_ldg}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('BtnCircle',{attrs:{"tLabel":"Atrás","tPosition":"bottom","icon":"mdi-arrow-left","color":"grey darken-3","size":"x-small","to":{
            name: 'contratantes',
          }}}),_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(_vm.$route.meta.title)}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"fab":"","x-small":"","color":"info","loading":_vm.faqs_ldg,"disabled":_vm.faqs.length == 0},on:{"click":function($event){$event.stopPropagation();_vm.faqs_dlg = true}}},on),[_c('v-icon',[_vm._v(" mdi-help ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Ayuda')}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","color":"error","href":_vm.url_documents +
                '/migrations/formato_siniestralidad_2021_22.xlsx',"target":"_blank"}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-download')}})],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Descargar formato')}})])],1)],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"counter":"","truncate-length":"15","label":"Formato XLSX","accept":".xlsx","show-size":"","dense":"","disabled":_vm.tbl.length > 0},model:{value:(_vm.data.document_file_0),callback:function ($$v) {_vm.$set(_vm.data, "document_file_0", $$v)},expression:"data.document_file_0"}})],1),(_vm.tbl.length == 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","color":"success","disabled":_vm.data.document_file_0 == null},on:{"click":_vm.getTbldata}},[_vm._v(" Analizar "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-upload ")])],1)],1):_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":""},on:{"click":function($event){_vm.tbl = []}}},[_vm._v(" Cargar otro formato "),_c('v-icon',{attrs:{"rigth":""}},[_vm._v(" mdi-reload ")])],1)],1),(_vm.tbl.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":"","loading":_vm.tbl_ldg,"disabled":_vm.tbl_ldg}},[_c('v-card-title',[_c('span',{domProps:{"textContent":_vm._s('RESULTADO')}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.tbl_srch),callback:function ($$v) {_vm.tbl_srch=$$v},expression:"tbl_srch"}})],1),_c('v-data-table',{attrs:{"headers":_vm.tbl_hdrs,"items":_vm.tbl,"items-per-page":30,"search":_vm.tbl_srch,"loading":_vm.tbl_ldg,"item-class":_vm.itemRowBackground,"item-key":"index","expanded":_vm.expanded,"show-expand":"","dense":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(item.index)}})]}}],null,false,3615550064)}),_c('div',{staticClass:"text-right pt-5 pb-3"},[_c('v-btn',{attrs:{"color":"warning"},on:{"click":function($event){$event.preventDefault();return _vm.execMigration.apply(null, arguments)}}},[_vm._v(" Guardar "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-check ")])],1)],1)],1)],1):_vm._e()],1)],1),_c('FaqDlg',{attrs:{"faqs":_vm.faqs},model:{value:(_vm.faqs_dlg),callback:function ($$v) {_vm.faqs_dlg=$$v},expression:"faqs_dlg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }